import React from 'react';
import {
    Dialog,
    Box,
    SvgIcon,
    Typography,
    Button,
    IconButton
} from '@mui/material';
import { get } from "lodash";
import CloseIcon from '@mui/icons-material/Close';

const Alert = ({
    title,
    subtitle,
    btn1 = { label: "", func: () => { } },
    btn2 = { label: "", func: () => { } },
    openAlert,
    closeAlert
}) => {

    return (
        <div>
            <Dialog onClose={closeAlert} open={openAlert}>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlert}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className='flex flex-col justify-center m-10'>
                    <SvgIcon sx={{ width: 244, height: 148, mx: "auto", mt: 3 }} viewBox="0 0 244 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M225.813 62.1865C235.611 61.4625 242.811 50.8278 241.895 38.4332C240.979 26.0387 232.294 16.5779 222.496 17.3019C212.699 18.026 205.499 28.6607 206.415 41.0552C207.331 53.4498 216.016 62.9106 225.813 62.1865Z" fill="#63E298" />
                        <path d="M225.441 62.1867H224.584V147.673H225.441V62.1867Z" fill="#353535" />
                        <path d="M225.654 60.3814L225.756 63.4106L227.125 66.2148L221.414 65.9235L223.815 63.5087L223.543 60.7563L225.654 60.3814Z" fill="#63E298" />
                        <path d="M43.1048 144.273L32.37 108.204L31.5483 108.449L42.2455 144.391" fill="#353535" />
                        <path d="M55.3776 147.532L66.1124 111.463L66.9341 111.708L56.237 147.65" fill="#353535" />
                        <path d="M43.4765 62.1866C53.2741 61.4626 60.4741 50.8278 59.5581 38.4333C58.6422 26.0387 49.9571 16.5779 40.1595 17.302C30.362 18.026 23.162 28.6607 24.0779 41.0553C24.9939 53.4498 33.6789 62.9106 43.4765 62.1866Z" fill="#63E298" />
                        <path d="M43.1034 62.1867H42.2461V147.673H43.1034V62.1867Z" fill="#353535" />
                        <path d="M32.8527 107.159L33.8151 110.033L35.9269 112.331L30.3696 113.68L31.9821 110.681L30.9371 108.12L32.8527 107.159Z" fill="#63E298" />
                        <path d="M65.6307 110.418L64.6683 113.292L62.5565 115.59L68.1138 116.939L66.5013 113.94L67.5463 111.379L65.6307 110.418Z" fill="#8FA0FF" />
                        <path d="M43.3176 60.3814L43.4205 63.4106L44.7894 66.2149L39.0781 65.9236L41.4789 63.5088L41.2075 60.7564L43.3176 60.3814Z" fill="#63E298" />
                        <path d="M33.1412 107.913C42.317 104.402 46.1557 92.1468 41.7152 80.5388C37.2747 68.9308 26.2365 62.3662 17.0606 65.8763C7.88478 69.3864 4.04604 81.6421 8.48656 93.2501C12.9271 104.858 23.9653 111.423 33.1412 107.913Z" fill="#63E298" />
                        <path d="M65.3422 111.172C56.1664 107.661 52.3277 95.4058 56.7682 83.7978C61.2087 72.1898 72.2469 65.6252 81.4228 69.1353C90.5986 72.6454 94.4374 84.9011 89.9968 96.5091C85.5563 108.117 74.5181 114.682 65.3422 111.172Z" fill="#8FA0FF" />
                        <path d="M190.016 26.4379H67.3453C53.4343 26.4379 42.1572 34.5338 42.1572 44.5206V129.917C42.1572 139.904 53.4343 148 67.3452 148H190.016C203.927 148 215.204 139.904 215.204 129.917V44.5206C215.204 34.5338 203.927 26.4379 190.016 26.4379Z" fill="white" />
                        <path d="M189.508 148H67.6763C53.5561 148 42.0684 139.706 42.0684 129.511V44.2125C42.0684 34.0173 53.5561 25.7228 67.6763 25.7228H189.508C203.628 25.7228 215.116 34.0173 215.116 44.2125V129.511C215.116 139.706 203.628 148 189.508 148ZM67.6763 26.5782C54.2093 26.5782 43.2531 34.4889 43.2531 44.2125V129.511C43.2531 139.234 54.2093 147.145 67.6763 147.145H189.508C202.975 147.145 213.931 139.234 213.931 129.511V44.2125C213.931 34.4889 202.975 26.5782 189.508 26.5782H67.6763Z" fill="#353535" />
                        <path d="M182.926 35.5319C184.055 35.5319 184.97 34.6166 184.97 33.4877C184.97 32.3587 184.055 31.4435 182.926 31.4435C181.797 31.4435 180.882 32.3587 180.882 33.4877C180.882 34.6166 181.797 35.5319 182.926 35.5319Z" fill="#353535" />
                        <path d="M188.548 35.5319C189.677 35.5319 190.592 34.6166 190.592 33.4877C190.592 32.3587 189.677 31.4435 188.548 31.4435C187.419 31.4435 186.503 32.3587 186.503 33.4877C186.503 34.6166 187.419 35.5319 188.548 35.5319Z" fill="#353535" />
                        <path d="M194.169 35.5319C195.298 35.5319 196.213 34.6166 196.213 33.4877C196.213 32.3587 195.298 31.4435 194.169 31.4435C193.04 31.4435 192.125 32.3587 192.125 33.4877C192.125 34.6166 193.04 35.5319 194.169 35.5319Z" fill="#353535" />
                        <path d="M58.202 117.252C57.2169 117.252 56.4155 118.054 56.4155 119.039C56.4155 119.52 56.601 119.965 56.9378 120.293C57.2774 120.641 57.7223 120.827 58.202 120.827H201.219C202.204 120.827 203.005 120.025 203.005 119.039C203.005 118.559 202.82 118.114 202.483 117.786C202.143 117.438 201.698 117.252 201.219 117.252H58.202Z" fill="#E4E4E4" />
                        <path d="M168.726 117.252V120.827H59.1575C58.219 120.827 57.3658 120.63 56.7515 120.3C56.12 119.978 55.7446 119.531 55.7446 119.039C55.7446 118.056 57.2805 117.252 59.1575 117.252L168.726 117.252Z" fill="#63E298" />
                        <path d="M199.78 111.531H183.435C181.633 111.531 180.167 110.248 180.167 108.671C180.167 107.093 181.633 105.811 183.435 105.811H199.78C201.582 105.811 203.049 107.093 203.049 108.671C203.049 110.248 201.582 111.531 199.78 111.531Z" fill="#E4E4E4" />
                        <path d="M129.057 95.8115C155.331 95.8115 176.631 74.5119 176.631 48.2376C176.631 21.9632 155.331 0.663666 129.057 0.663666C102.782 0.663666 81.4829 21.9632 81.4829 48.2376C81.4829 74.5119 102.782 95.8115 129.057 95.8115Z" fill="white" />
                        <path d="M129.06 96.4751C119.519 96.4751 110.193 93.6461 102.261 88.3457C94.3279 83.0453 88.1452 75.5116 84.4942 66.6974C80.8432 57.8831 79.8879 48.1842 81.7491 38.827C83.6104 29.4699 88.2045 20.8747 94.9506 14.1286C101.697 7.38243 110.292 2.78826 119.649 0.926965C129.006 -0.934328 138.705 0.0208823 147.519 3.67182C156.334 7.32275 163.867 13.5054 169.168 21.438C174.468 29.3706 177.297 38.6968 177.297 48.2372C177.283 61.0262 172.196 73.2873 163.153 82.3306C154.11 91.3738 141.849 96.4606 129.06 96.4751ZM129.06 1.32733C119.782 1.32733 110.712 4.0786 102.998 9.23318C95.2835 14.3878 89.2709 21.7141 85.7204 30.2859C82.1699 38.8576 81.2409 48.2897 83.051 57.3894C84.8611 66.4891 89.3288 74.8477 95.8894 81.4082C102.45 87.9687 110.809 92.4365 119.908 94.2465C129.008 96.0565 138.44 95.1275 147.012 91.5769C155.584 88.0263 162.91 82.0137 168.064 74.2993C173.219 66.5849 175.97 57.5152 175.97 48.2372C175.956 35.8002 171.009 23.8767 162.215 15.0824C153.42 6.2881 141.497 1.34132 129.06 1.32733Z" fill="#353535" />
                        <path d="M122.57 72.9972C121.749 72.9972 120.939 72.8059 120.204 72.4384C119.469 72.0709 118.83 71.5373 118.337 70.88L105.358 53.5745C104.941 53.0186 104.637 52.3858 104.464 51.7124C104.291 51.0389 104.252 50.338 104.35 49.6496C104.448 48.9612 104.681 48.2989 105.035 47.7006C105.389 47.1022 105.858 46.5795 106.414 46.1623C106.971 45.7451 107.604 45.4417 108.277 45.2693C108.951 45.0969 109.652 45.0589 110.34 45.1576C111.028 45.2563 111.69 45.4896 112.288 45.8443C112.886 46.1989 113.409 46.668 113.825 47.2246L122.317 58.5454L144.125 25.8329C144.511 25.2546 145.006 24.758 145.584 24.3713C146.161 23.9846 146.809 23.7154 147.491 23.5791C148.172 23.4428 148.874 23.4421 149.556 23.5769C150.237 23.7118 150.886 23.9796 151.464 24.3651C152.042 24.7506 152.539 25.2462 152.926 25.8237C153.312 26.4011 153.582 27.049 153.718 27.7305C153.854 28.412 153.855 29.1136 153.72 29.7953C153.585 30.4771 153.317 31.1256 152.932 31.7039L126.974 70.6405C126.504 71.3447 125.873 71.926 125.132 72.3358C124.392 72.7455 123.564 72.9717 122.718 72.9954C122.669 72.9963 122.62 72.9972 122.57 72.9972Z" fill="#63E298" />
                    </SvgIcon>
                    <Typography className='text-center' variant="heading2" sx={{ mt: 7, mb: 1 }}>
                        {title}
                    </Typography>
                    <Typography className='text-center' variant="bodyMedium">
                        {subtitle}
                    </Typography>
                    <Button variant="contained" color="primary" sx={{ mt: 5 }} onClick={get(btn1, "func")}>
                        {get(btn1, "label")}
                    </Button>
                    <Button variant="text" color="secondary" sx={{ mt: 1 }} onClick={get(btn2, "func")}>
                        {get(btn2, "label")}
                    </Button>
                </Box>
            </Dialog>
        </div >
    );
}

export default Alert